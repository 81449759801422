import zoid from 'zoid';

import { decodeObject, encodeObject } from 'utils/encoding';

if (!window.flywire) {
  const embedded = zoid.create({
    tag: 'fw-payment',
    dimensions: {
      width: '100%',
      height: '800px',
    },
    url: config => {
      const urls = {
        development: 'https://payex-web-development.flywire.cc',
        demo: 'https://payment.demo.flywire.com/',
        local: 'http://localhost:5300/',
        app: 'http://payex/',
        production: 'https://payment.flywire.com/',
        apache: 'https://payex-web-dev-apache.flywire.cc',
        barraca: 'https://payex-web-dev-barraca.flywire.cc',
        espiral: 'https://payex-web-dev-espiral.flywire.cc',
        puzzle: 'https://payex-web-dev-puzzle.flywire.cc',
        rockola: 'https://payex-web-dev-rockola.flywire.cc',
        tuculca: 'https://payex-web-dev-tuculca.flywire.cc',
        staging: 'https://payment.flywire.lol',
        qa: 'https://payment.qa.flywire.cc/',
      };

      const { props } = decodeObject(config);
      return urls[props.env];
    },
    defaultEnv: 'demo',
    scrolling: true,
    defaultLogLevel: 'error',
  });

  const embeddedAdapter = {
    Payment: {
      render: (config, selector) => {
        const encodedConfig = encodeObject(config);

        return embedded(encodedConfig).render(selector);
      },
    },
  };

  window.flywire = embeddedAdapter;
  window.requester = 'payex';
}
